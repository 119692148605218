
  .vab-layout-wrap {
    .vab-sider {
      position: fixed;
      left: 0;
      height: 100vh;
      overflow: auto;

      .vab-menu {
        height: calc(100vh -110px);
      }
    }

    .vab-layout {
      padding-left: 208px;
      transition: all 0.2s;
    }

    .vab-mobile-layout {
      padding-left: 0;
      transition: all 0.2s;
    }

    .vab-collapse {
      .vab-logo .anticon+span {
        display: inline-block;
        max-width: 0;
        opacity: 0;
        transition: all 0.2s;
      }

      &+.vab-layout {
        padding-left: 81px;
        transition: all 0.2s;
      }
    }

    .vab-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 998;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: #000;
      opacity: 0.5;
    }

    .vab-mobile {
      position: fixed !important;
      z-index: 999;

      &.vab-collapse {
        width: 0 !important;
        min-width: 0 !important;
        max-width: 0 !important;

        * {
          display: none !important;
          width: 0 !important;
          min-width: 0 !important;
          max-width: 0 !important;
        }

        .ant-menu-item,
        .ant-menu-submenu {
          display: none !important;
          width: 0 !important;
          min-width: 0 !important;
          max-width: 0 !important;
        }

        &+.vab-layout {
          padding-left: 0px !important;
          transition: all 0.2s;
        }
      }
    }

    .vab-header {
      padding: 0;
      background: #fff;
      box-shadow: 0 3px 6px 1px rgba(229, 229, 239, 0.10);

      .ant-col+.ant-col {
        display: flex;
        justify-content: flex-end;
        padding: 0 @vab-padding;
      }

      .trigger {
        height: @vab-header-height;
        padding: 0 @vab-padding;
        font-size: 18px;
        line-height: @vab-header-height;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .ant-layout {
      background: #F9FAFC;
    }

    .ant-layout-sider {
      background: #FFFFFF;
      box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.08);
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      color: #333333;
      background: #FFFFFF;

      .ant-menu-title-content {
        padding: 0 8px;
        margin: 0 25px 0 5px;
        border-radius: 8px;
      }
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
      color: #333333;
    }

    .ant-menu-dark .ant-menu-item:hover,
    .ant-menu-dark .ant-menu-item-active,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
      color: #333333;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #fff;

      .ant-menu-title-content {
        background: #F5F6F6;

        img {
          filter: invert(91%) sepia(55%) saturate(592%) hue-rotate(184deg) brightness(106%) contrast(99%);
        }
      }

      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        content: '';
        width: 5px;
        height: 25px;
        border-radius: 0 4px 4px 0;
        background: #4687FE;
      }
    }

    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }

  .ant-drawer-content-wrapper {
    height: calc(100vh - 85px) !important;
    bottom: 0;

    .ant-drawer-content {
      background: #F8F8F8;
    }

    .ant-drawer-header {
      background: #F8F8F8;
      border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
    }

    .ant-drawer-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }

    .ant-form-item-control-input-content {
      >.ant-input-affix-wrapper {
        width: 220px;
      }

      >.ant-input {
        width: 220px;
      }

      .remark {
        width: 470px;
        height: 150px;
        overflow-y: auto;
        align-items: flex-start;
      }
    }

    .ant-form-item {
      margin-right: 30px !important;

      &:nth-last-child(1) {
        height: 185px !important;
      }

      &:nth-child(2n-1) {
        margin-right: 0 !important;
      }
    }

    .ant-form-item-label>label::after {
      display: none !important;
    }

    .ant-form-horizontal .ant-form-item-label {
      height: 32px !important;
    }
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 30px;@vab-header-height: 65px;